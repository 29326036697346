import type { MutationHookOptions } from '@apollo/client'
import type {
  CreateFieldParams,
  Field,
  GetFieldsQuery,
  GetWorkspaceFieldsQuery,
  UpdateFieldParams,
} from '#field/types'
import {
  ADD_UPDATE_FIELD_MUTATION,
  CREATE_FIELD_MUTATION,
  DELETE_FIELD_MUTATION,
  UPDATE_FIELD_MUTATION,
  FIELDS_QUERY,
  WORKSPACE_FIELDS_QUERY,
  FIELD_QUERY,
} from '#field/schema'

export const useFieldsQuery = (params: GetFieldsQuery) => {
  return useQuery<{ fields: Field[] }>(FIELDS_QUERY, params)
}

export const useFieldsLazyQuery = (params: GetFieldsQuery) => {
  return useLazyQuery<{ fields: Field[] }>(FIELDS_QUERY, params)
}

export const useWorkspaceFieldsQuery = (params: GetWorkspaceFieldsQuery) => {
  return useQuery<{ workspaceFields: Field[] }>(WORKSPACE_FIELDS_QUERY, params)
}

export const useWorkspaceFieldsLazyQuery = (params: GetWorkspaceFieldsQuery) => {
  return useLazyQuery<{ workspaceFields: Field[] }>(WORKSPACE_FIELDS_QUERY, params)
}

export const useFieldLazyQuery = (id: string) => {
  return useLazyQuery<{ field: Field }>(FIELD_QUERY, { id })
}

export const useAddUpdateFieldMutation = (
  options: MutationHookOptions<{
    addUpdateField: { field: Field }
  }>
) => {
  return useMutation(ADD_UPDATE_FIELD_MUTATION, {
    ...defaultMutationOptions,
    ...options,
  })
}

export const useCreateFieldMutation = (
  params: CreateFieldParams,
  options: MutationHookOptions = {}
) => {
  if (params.options) {
    params.options = JSON.stringify(params.options)
  }

  return useMutation(CREATE_FIELD_MUTATION, {
    ...defaultMutationOptions,
    variables: params,
    ...options,
  })
}

export const useUpdateFieldMutation = (
  params: UpdateFieldParams,
  options: MutationHookOptions = {}
) => {
  if (params.options) {
    params.options = JSON.stringify(params.options)
  }

  return useMutation(UPDATE_FIELD_MUTATION, {
    ...defaultMutationOptions,
    variables: params,
    ...options,
  })
}

export const useDeleteFieldMutation = (
  id: string,
  options: MutationHookOptions = {}
) => {
  return useMutation(DELETE_FIELD_MUTATION, {
    ...defaultMutationOptions,
    variables: {
      id,
    },
    ...options,
  })
}
